.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: [header-start] minmax(10rem, max-content) [header-end body-start] max-content [body-end footer-start] minmax(10rem, max-content) [footer-end];
  background-color: var(--color-primary); }

.header {
  grid-column: 1/-1;
  grid-row: 1/2;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  position: sticky;
  top: 0;
  left: 0; }

.header__navigation {
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 37.5em) {
    .header__navigation {
      display: none; } }
  .header__navigation-item:link, .header__navigation-item:visited {
    min-width: 15rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 0.4rem;
    padding: 1rem 2rem;
    margin: 2rem 2rem;
    border-radius: var(--border-radius);
    position: relative;
    transition: transform 0.3s; }
    .header__navigation-item:link.primary, .header__navigation-item:visited.primary {
      color: var(--color-secondary); }
    .header__navigation-item:link.secondary, .header__navigation-item:visited.secondary {
      color: var(--color-primary); }
    @media only screen and (max-width: 75em) {
      .header__navigation-item:link, .header__navigation-item:visited {
        margin: 2rem 0.2rem; } }
    @media only screen and (max-width: 56.25em) {
      .header__navigation-item:link, .header__navigation-item:visited {
        min-width: 10rem;
        margin: 2rem 0.7rem;
        padding: 1rem 1rem; } }
    .header__navigation-item:link:hover.primary, .header__navigation-item:link.activated.primary, .header__navigation-item:visited:hover.primary, .header__navigation-item:visited.activated.primary {
      transform: translateY(-0.4rem);
      background-color: var(--color-secondary);
      color: var(--color-primary);
      box-shadow: 0.5rem 0.5rem 3rem var(--color-secondary); }
    .header__navigation-item:link:hover.secondary, .header__navigation-item:link.activated.secondary, .header__navigation-item:visited:hover.secondary, .header__navigation-item:visited.activated.secondary {
      transform: translateY(-0.4rem);
      background-color: var(--color-primary);
      color: var(--color-secondary);
      box-shadow: 0.5rem 0.5rem 3rem var(--color-primary); }
    .header__navigation-item:link:active, .header__navigation-item:visited:active {
      transform: translateY(-0.1rem); }

.hamburger {
  display: none;
  position: absolute;
  top: 5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999; }
  @media only screen and (max-width: 37.5em) {
    .hamburger {
      display: block; } }

.drawer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  animation: drawer 0.5s cubic-bezier(0, 0.96, 0.67, 1.23);
  color: var(--color-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0.5rem 0.5rem 3rem --color-secondary; }
  .drawer__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20rem 10rem; }

.heading {
  font-family: inherit; }
  .heading-1 {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 3.12rem; }
  .heading-2 {
    font-size: 4.8rem;
    font-weight: 400;
    line-height: 4.8rem; }
  .heading-3 {
    font-size: 7.2rem;
    font-weight: 400;
    line-height: 7.8rem; }

.paragraph {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.6rem; }

:root {
  --color-primary: black;
  --color-secondary: white;
  --border-radius: 3rem; }

.home {
  grid-row: body-start/body-end;
  grid-column: 1/-1;
  position: relative;
  justify-items: center;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(4, [home-col-start] 1fr [home-col-end]);
  grid-template-rows: [home-greet-start] minmax(90vh, max-content) [home-greet-end home-followers-start] minmax(min-content, max-content) [home-followers-end home-quotes-start] minmax(50rem, max-content) [home-quotes-end home-gallery1-start] 1fr [home-gallery1-end home-gallery2-start] 1fr [home-gallery2-end]; }

.home__greed-container {
  grid-row: home-greet-start/home-greet-end;
  grid-column: 1/-1;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 90%); }
  .home__greed-container::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
    background-color: var(--color-secondary); }
  @media only screen and (max-width: 75em) {
    .home__greed-container {
      flex-direction: column;
      clip-path: polygon(0 0, 100% 0, 100% 90%, 0 95%); } }
  @media only screen and (max-width: 56.25em) {
    .home__greed-container {
      flex-direction: column;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); } }
  @media only screen and (max-width: 37.5em) {
    .home__greed-container {
      flex-direction: column;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); } }
  .home__greed-container::after {
    content: "FULLSTACK DEVELOPER";
    letter-spacing: -0.5rem;
    font-size: 5rem;
    transform: rotate(-2.5deg);
    opacity: 0.05;
    position: absolute;
    top: 80%;
    left: 5%;
    z-index: 1; }
    @media only screen and (max-width: 75em) {
      .home__greed-container::after {
        top: 85%; } }
    @media only screen and (max-width: 56.25em) {
      .home__greed-container::after {
        top: 90%;
        transform: rotate(0deg);
        left: 5%;
        font-size: 4rem; } }
    @media only screen and (max-width: 37.5em) {
      .home__greed-container::after {
        display: none; } }

.greet__content {
  flex-basis: 50%;
  padding: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; }
  @media only screen and (max-width: 75em) {
    .greet__content {
      flex-basis: 50%;
      padding: 5rem; } }
  @media only screen and (max-width: 56.25em) {
    .greet__content {
      flex-basis: 20%;
      padding: 5rem; } }
  @media only screen and (max-width: 37.5em) {
    .greet__content {
      padding: 2rem; } }

.greet__image-container {
  flex-basis: 50%;
  z-index: 1;
  position: relative; }
  @media only screen and (max-width: 75em) {
    .greet__image-container {
      flex-basis: 50%; } }
  @media only screen and (max-width: 56.25em) {
    .greet__image-container {
      flex-basis: 80%; } }

.greet__image {
  width: 100%;
  height: 100%; }
  .greet__image--bg {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
    transform: scale(0.8);
    filter: grayscale(1); }
    @media only screen and (max-width: 75em) {
      .greet__image--bg {
        transform: scale(0.9); } }
    @media only screen and (max-width: 56.25em) {
      .greet__image--bg {
        transform: scale(1); } }

.latest {
  grid-column: 1/-1;
  display: flex;
  padding: 5rem 5rem; }
  .latest.latest-1 {
    color: var(--color-secondary);
    grid-row: home-gallery1-start/home-gallery1-end; }
  .latest.latest-2 {
    flex-direction: row-reverse;
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
    background-color: var(--color-secondary);
    grid-row: home-gallery2-start/home-gallery2-end; }
    @media only screen and (max-width: 75em) {
      .latest.latest-2 {
        clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 100%); } }
    @media only screen and (max-width: 56.25em) {
      .latest.latest-2 {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); } }
  .latest__content {
    width: 80%; }
    .latest__content.latest__content-1 {
      grid-column: 1/3;
      grid-row: home-gallery1-start/home-gallery1-end; }
    .latest__content.latest__content-2 {
      grid-column: 3/5;
      grid-row: home-gallery2-start/home-gallery2-end; }
  .latest__gallery {
    margin: 10rem auto;
    width: 80%;
    grid-column: 3/5;
    display: grid;
    gap: 2rem;
    justify-content: center;
    align-content: center;
    grid-template-columns: repeat(2, 25rem);
    grid-template-rows: repeat(2, 25rem); }
    .latest__gallery.latest__gallery-1 {
      grid-column: 3/5;
      grid-row: home-gallery1-start/home-gallery1-end; }
    .latest__gallery.latest__gallery-2 {
      grid-column: 1/3;
      grid-row: home-gallery2-start/home-gallery2-end; }
    @media only screen and (max-width: 56.25em) {
      .latest__gallery {
        grid-template-columns: repeat(2, 20rem);
        grid-template-rows: repeat(2, 20rem); } }
    @media only screen and (max-width: 37.5em) {
      .latest__gallery {
        display: flex;
        flex-direction: column; } }
    .latest__gallery-container {
      width: 100%;
      height: 100%; }
      @media only screen and (max-width: 56.25em) {
        .latest__gallery-container {
          height: 20rem; } }
      @media only screen and (max-width: 37.5em) {
        .latest__gallery-container {
          height: 15rem; } }
    .latest__gallery-picture {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: transform 0.3s ease-out;
      filter: grayscale(1);
      cursor: pointer; }
      .latest__gallery-picture:hover {
        transform: scale(1.25) translateY(-1rem);
        border: 1rem solid transparent;
        z-index: 2;
        filter: grayscale(0); }

.quotes__container {
  grid-column: 1/-1;
  grid-row: home-quotes-start/home-quotes-end;
  clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-secondary); }
  @media only screen and (max-width: 75em) {
    .quotes__container {
      clip-path: polygon(0 15%, 100% 0, 100% 85%, 0 100%); } }
  @media only screen and (max-width: 56.25em) {
    .quotes__container {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); } }

.quotes__carousel-container {
  width: 70%;
  color: var(--color-primary);
  position: relative;
  margin-top: -35rem; }
  @media only screen and (max-width: 75em) {
    .quotes__carousel-container {
      width: 75%;
      margin-top: -37rem; } }
  @media only screen and (max-width: 56.25em) {
    .quotes__carousel-container {
      width: 80%;
      margin-top: -40rem; } }
  @media only screen and (max-width: 37.5em) {
    .quotes__carousel-container {
      width: 85%;
      margin-top: -58rem; } }

.followers {
  grid-row: home-followers-start/home-followers-end;
  grid-column: 1/-1;
  width: 100%;
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  color: var(--color-secondary); }
  .followers__strat {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap; }
  .followers__holder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 30rem;
    margin: 3rem 0; }
  .followers__comments {
    display: flex;
    align-items: center;
    justify-content: center; }

.contactMe {
  grid-row: 2/3;
  grid-column: 1/-1;
  padding: 5rem 0;
  position: relative;
  overflow: hidden; }
  .contactMe::after {
    background-image: url("../../src/assets/img/contactMeBg2.jpg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    filter: grayscale(1);
    transition: transform 20s;
    transform: scale(1); }
  .contactMe:hover::after {
    transform: scale(1.5); }
  .contactMe__heading {
    position: relative;
    text-transform: uppercase;
    z-index: 1; }
  .contactMe__paragraph {
    position: relative;
    min-width: 40rem;
    width: 30%;
    margin: 0 auto;
    z-index: 1;
    padding: 3rem; }
  .contactMe__widget-container {
    position: relative;
    z-index: 1;
    width: 40%;
    min-width: 40rem;
    margin: 0 auto; }
    @media only screen and (max-width: 56.25em) {
      .contactMe__widget-container {
        width: 50%; } }

.project {
  grid-row: body-start/body-end;
  grid-column: 1/-1;
  min-height: 60rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.txt-ctr {
  text-align: center; }

.mt-medium {
  margin-top: 1.8rem; }

.mt-large {
  margin-top: 5rem; }

.mt-xlarge {
  margin-top: 15rem; }

.ml-medium {
  margin-left: 1.8rem; }

.ml-large {
  margin-left: 5rem; }

.ml-xlarge {
  margin-left: 15rem; }

.mr-medium {
  margin-right: 1.8rem; }

.mr-large {
  margin-right: 5rem; }

.mr-xlarge {
  margin-right: 15rem; }

.ctr {
  margin: 0 auto;
  display: block; }

.clr-secondary {
  color: var(--color-secondary); }

.clr-primary {
  color: var(--color-primary); }

.fadeInLeft {
  animation: fromLeft 1.1s ease-out; }

.fadeInTop {
  animation: fromTop 1.1s ease-out; }

.fadeInRight {
  animation: fromRight 1.1s ease-out; }

.fadeInBottom {
  animation: fromBottom 1.1s ease-out; }

.lineClamp_4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.lineClamp_3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.btn {
  border: none;
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.4rem;
  padding: 2rem 3rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  letter-spacing: 0.3rem;
  position: relative;
  transition: all 0.2s; }
  .btn.primary {
    background-color: var(--color-secondary);
    color: var(--color-primary); }
    .btn.primary:hover {
      background-color: var(--color-primary);
      color: var(--color-secondary);
      transform: translateY(-0.4rem);
      box-shadow: 0.5rem 0.5rem 3rem var(--color-secondary); }
  .btn.secondary {
    background-color: var(--color-primary);
    color: var(--color-secondary); }
    .btn.secondary:hover {
      background-color: var(--color-secondary);
      color: var(--color-primary);
      transform: translateY(-0.4rem);
      box-shadow: 0.5rem 0.5rem 3rem var(--color-primary); }
  .btn:active {
    transform: translateY(-0.2rem); }

@keyframes fromLeft {
  0% {
    transform: translateX(-50%); }
  100% {
    transform: translate(1); } }

@keyframes fromRight {
  0% {
    transform: translateX(50%); }
  100% {
    transform: translate(1); } }

@keyframes fromBottom {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translate(1); } }

@keyframes fromTop {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translate(1); } }

@keyframes glow {
  0% {
    filter: hue-rotate(0deg); }
  100% {
    filter: hue-rotate(360deg); } }

@keyframes blowUpModal {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes drawer {
  0% {
    height: 0rem;
    width: 100%; }
  100% {
    height: 100rem;
    width: 100%; } }

.footer {
  grid-row: footer-start/footer-end;
  grid-column: 1/-1;
  color: var(--color-primary);
  font-size: 3rem;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), url("../../src/assets/img/my_eye.png");
  background-position: center;
  background-size: cover;
  filter: grayscale(1);
  padding: 1.2rem;
  text-align: center; }

.copyright {
  margin: 1rem 0; }

.allrights {
  margin: 1rem auto;
  width: 60%;
  min-width: 30rem; }

.logo {
  height: 6rem;
  padding: 0 4rem; }
  .logo__container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20rem; }
    @media only screen and (max-width: 37.5em) {
      .logo__container {
        margin-bottom: 5rem; } }

.social-media__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }
  .social-media__container > .avatar {
    margin: 2rem 3rem; }

.avatar__icon {
  width: 2rem;
  height: 2rem; }

.avatar {
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color-secondary);
  cursor: pointer;
  overflow: hidden;
  padding: 1rem; }
  .avatar:hover {
    background-color: var(--color-primary);
    box-shadow: 0.5rem 0.5rem 3rem var(--color-secondary); }
    .avatar:hover .avatar__icon {
      color: var(--color-secondary); }
  .avatar.dark {
    background-color: var(--color-primary);
    color: var(--color-secondary); }
    .avatar.dark:hover {
      background-color: var(--color-secondary);
      box-shadow: 0.5rem 0.5rem 3rem var(--color-primary);
      color: var(--color-primary); }
      .avatar.dark:hover .avatar__icon {
        color: var(--color-primary); }

.carousel-item {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  padding: 5rem 10rem;
  width: 100%;
  height: 40rem;
  flex-direction: column; }
  @media only screen and (max-width: 37.5em) {
    .carousel-item {
      padding: 1rem 2rem; } }

.carousel__img-holder {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  overflow: hidden; }

.carousel__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(1);
  transition: all 0.3s; }
  .carousel__img:hover {
    filter: grayscale(0);
    transform: scale(1.3); }

.arrow-prev {
  left: -3rem;
  position: absolute; }

.arrow-next {
  right: -3rem;
  position: absolute;
  display: flex; }
  .arrow-next > a {
    display: block; }

/*Select every element*/
[id^="item"] {
  display: none; }

.item-1 {
  z-index: 2;
  opacity: 1; }

*:target ~ .item-1 {
  opacity: 0; }

#item-1:target ~ .item-1 {
  opacity: 1; }

#item-2:target ~ .item-2,
#item-3:target ~ .item-3 {
  z-index: 3;
  opacity: 1; }

.mentor-speech {
  padding: 3rem;
  height: 20rem; }

.form {
  position: relative;
  z-index: 1;
  width: 100%; }
  .form__group {
    padding-top: 3rem; }
  .form__input {
    width: 100%;
    transition: all 0.3s;
    font-family: inherit;
    padding: 2rem;
    outline: none;
    border: none;
    border-radius: var(--border-radius); }
    .form__input::placeholder {
      color: var(--color-primary); }
    .form__input:focus-visible, .form__input:focus {
      background-color: var(--color-primary);
      color: var(--color-secondary);
      box-shadow: 0.5rem 0.5rem 3rem var(--color-secondary); }
      .form__input:focus-visible::placeholder, .form__input:focus::placeholder {
        color: var(--color-secondary); }
    .form__input.textarea {
      min-height: 20rem; }

.modal {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  background-color: rgba(0, 0, 0, 0.85); }
  .modal__body {
    position: absolute;
    padding: 6rem;
    opacity: 1;
    width: 75%;
    min-height: 50%;
    height: 70%;
    background-color: var(--color-secondary);
    z-index: 99999;
    transition: all 0.3s;
    border-radius: var(--border-radius);
    box-shadow: 0.5rem 0.5rem 3rem var(--color-secondary); }
    .modal__body.show {
      display: flex;
      animation: blowUpModal 0.5s cubic-bezier(0, 0.96, 0.67, 1.23);
      justify-content: center; }
  .modal__close-btn {
    position: absolute;
    right: 0rem;
    top: 0rem;
    transform: translate(50%, -50%);
    box-shadow: 0.5rem 0.5rem 3rem var(--color-secondary); }
  .modal__img {
    flex-basis: 100%;
    position: relative; }
    .modal__img--modifier {
      position: relative;
      z-index: 1;
      height: 100%;
      width: 100%;
      object-fit: contain;
      display: block; }
    .modal__img-controller {
      z-index: 2;
      display: flex;
      position: absolute;
      bottom: -5rem;
      left: 50%;
      min-width: 20rem;
      justify-content: space-around;
      align-items: center;
      transform: translate(-50%, 0); }

.about-me__container {
  grid-row: body-start/body-end;
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: minmax(10rem, min-content) minmax(10rem, min-content) minmax(min-content, max-content) minmax(10rem, max-content) minmax(min-content, max-content) minmax(10rem, max-content);
  position: relative;
  overflow: hidden; }
  .about-me__container::after {
    grid-row: body-start/body-end;
    content: "";
    position: absolute;
    background-image: url("../../src/assets/img/me_about_me.jpg");
    background-attachment: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    filter: grayscale(1);
    opacity: 0.25;
    background-size: cover;
    background-position: top; }

.about-me__title {
  grid-row: 1/2;
  text-transform: uppercase;
  padding: 1rem;
  background-color: var(--color-secondary);
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column; }

.about-me__heading {
  grid-row: 3/4;
  position: relative;
  z-index: 1; }

.about-me__content {
  display: flex;
  justify-content: center;
  grid-row: 4/5;
  grid-column: 1/-1;
  padding: 0 5rem;
  position: relative;
  z-index: 1; }
  @media only screen and (max-width: 75em) {
    .about-me__content {
      flex-direction: column;
      padding: 0 0; } }
  @media only screen and (max-width: 56.25em) {
    .about-me__content {
      flex-direction: column;
      padding: 0 0; } }
  @media only screen and (max-width: 37.5em) {
    .about-me__content {
      padding: 0 0; } }

.about-me__img-container {
  display: flex;
  flex-basis: 50%;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-top: 5rem; }

.about-me__img {
  height: auto;
  width: 40vw;
  filter: grayscale(1);
  transition: all 0.3s;
  display: block;
  cursor: pointer;
  overflow: hidden; }
  .about-me__img:hover {
    filter: grayscale(0); }
  @media only screen and (max-width: 75em) {
    .about-me__img {
      width: 80vw; } }
  @media only screen and (max-width: 56.25em) {
    .about-me__img {
      width: 80vw; } }
  @media only screen and (max-width: 37.5em) {
    .about-me__img {
      width: 80vw; } }

.about-me__text {
  flex-basis: 50%;
  padding: 5rem;
  z-index: 1; }

.experience {
  grid-row: 5/6;
  padding: 5rem 0;
  background-color: var(--color-secondary); }
  .experience__heading {
    z-index: 1; }
  .experience__link {
    z-index: 1;
    margin: 0rem; }
  .experience__paragraph {
    margin-bottom: 0.5rem; }
  .experience__table {
    display: flex;
    margin-top: 5rem;
    padding: 3rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start; }
  .experience__professional {
    flex-basis: 50%;
    z-index: 1; }
    @media only screen and (max-width: 37.5em) {
      .experience__professional {
        flex-basis: 100%; } }
    .experience__professional--alignment {
      display: flex;
      margin-top: 3rem;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 2rem 0; }
      @media only screen and (max-width: 37.5em) {
        .experience__professional--alignment {
          margin-top: 0rem; } }
  .experience__education {
    flex-basis: 50%;
    z-index: 1; }
    @media only screen and (max-width: 37.5em) {
      .experience__education {
        flex-basis: 100%; } }

.abilities {
  padding: 5rem 0;
  z-index: 1;
  grid-row: 6/7; }
  .abilities__rating {
    width: 50%;
    margin: 3rem auto; }
  .abilities__heading {
    padding: 5rem 0 2rem 0; }
    @media only screen and (max-width: 75em) {
      .abilities__heading {
        text-align: center; } }
  .abilities__table {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem; }
    @media only screen and (max-width: 75em) {
      .abilities__table {
        justify-content: center; } }

.gallery__container {
  grid-row: body-start/body-end;
  grid-column: 1/-1;
  display: grid;
  background-color: var(--color-primary);
  grid-template-rows: minmax(80rem, max-content) 1fr min-content max-content;
  position: relative; }

.gallery__memory {
  display: flex;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 90%); }
  @media only screen and (max-width: 75em) {
    .gallery__memory {
      clip-path: polygon(0 0, 100% 0, 100% 85%, 0 90%); } }
  @media only screen and (max-width: 56.25em) {
    .gallery__memory {
      clip-path: polygon(0 0, 100% 0, 100% 90%, 0 90%); } }
  @media only screen and (max-width: 37.5em) {
    .gallery__memory {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); } }
  .gallery__memory-heading {
    padding: 10rem;
    background-color: var(--color-secondary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    @media only screen and (max-width: 37.5em) {
      .gallery__memory-heading {
        padding: 3rem; } }

.gallery_bg-img {
  width: 100%;
  display: block;
  transform: scale(1.2);
  margin-top: 10rem; }

.albums {
  grid-row: 2/3;
  grid-column: 1/-1;
  display: grid;
  background-color: var(--color-primary);
  grid-template-rows: min-content repeat(2, minmax(5vh, 1fr));
  grid-template-columns: 0.5fr repeat(4, 1fr) 0.5fr; }
  .albums__heading {
    grid-column: 1/-1;
    text-align: center;
    padding: 3rem 0; }
  .albums__layout {
    grid-column: 2 / span 4;
    grid-row: 2/-1;
    gap: 3rem;
    display: grid;
    justify-items: center;
    margin: 10rem 0;
    grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr)); }
  .albums__showmore {
    grid-column: 1/-1;
    display: flex;
    justify-content: center; }

.photographers {
  background-color: var(--color-secondary);
  grid-column: 1/-1;
  grid-row: 3/4;
  padding: 20rem;
  clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%); }
  @media only screen and (max-width: 56.25em) {
    .photographers {
      padding: 10rem;
      clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%); } }
  @media only screen and (max-width: 37.5em) {
    .photographers {
      padding: 2rem;
      clip-path: polygon(0 0%, 100% 0, 100% 100%, 0 100%); } }

.walloffame {
  grid-row: 4/5;
  min-height: 50rem;
  height: max-content;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 15rem 0 0 0;
  position: relative; }
  .walloffame__title {
    position: absolute;
    top: -10rem;
    width: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .walloffame__holder {
    min-height: 52rem;
    min-width: 32rem;
    margin: 3rem 5rem;
    position: relative; }
  .walloffame__container {
    display: flex;
    width: 20rem;
    height: 30rem;
    position: relative; }
  .walloffame__img {
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    cursor: pointer;
    filter: grayscale(1); }
    .walloffame__img:hover {
      transform: translateY(-1rem) scale(1.2);
      filter: grayscale(0); }
  .walloffame__img-holder {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
    .walloffame__img-holder.stack {
      z-index: 9;
      position: absolute; }
      .walloffame__img-holder.stack.stack-1 {
        margin: 4rem; }
      .walloffame__img-holder.stack.stack-2 {
        margin: 8rem; }
      .walloffame__img-holder.stack.stack-3 {
        margin: 12rem; }
  .walloffame__credit {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -80%); }

.albumCard {
  background-color: var(--color-secondary);
  margin: 2rem;
  position: relative;
  width: 25rem;
  min-height: 30rem;
  transition: all 0.3s;
  cursor: pointer; }
  .albumCard:hover {
    box-shadow: 0.5rem 0.5rem 3rem var(--color-secondary); }
    .albumCard:hover .albumCard__caption {
      transform: scale(1); }
    .albumCard:hover .albumCard__img {
      transform: scale(1.3);
      filter: grayscale(0); }

.albumCard__img-holder {
  padding: 2rem;
  overflow: hidden;
  width: 100%;
  height: 100%; }

.albumCard__img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  display: block;
  transition: all 0.3s;
  filter: grayscale(1); }

.albumCard__caption {
  background-color: var(--color-secondary);
  color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  position: absolute;
  top: 5rem;
  height: 10%;
  width: 100%;
  transform: scale(0);
  transition: all 0.3s; }

.card {
  width: 100%;
  min-height: 20rem;
  margin: 3rem auto;
  background-color: var(--color-secondary);
  color: var(--color-primary);
  box-shadow: 0.5rem 0.5rem 3rem var(--color-primary);
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  position: relative;
  cursor: pointer;
  transform: skewX(-10deg); }
  @media only screen and (max-width: 75em) {
    .card {
      transform: skewX(-7deg); } }
  @media only screen and (max-width: 56.25em) {
    .card {
      transform: skewX(-4deg); } }
  @media only screen and (max-width: 37.5em) {
    .card {
      transform: skewX(0deg); } }
  .card > * {
    transform: skewX(10deg); }
    @media only screen and (max-width: 75em) {
      .card > * {
        transform: skewX(7deg); } }
    @media only screen and (max-width: 56.25em) {
      .card > * {
        transform: skewX(4deg); } }
    @media only screen and (max-width: 37.5em) {
      .card > * {
        transform: skewX(0deg); } }
  .card:hover {
    transform: translateX(1rem) skewX(-10deg);
    background-color: var(--color-primary);
    color: var(--color-secondary);
    box-shadow: 0.5rem 0.5rem 3rem var(--color-primary); }
    .card:hover .card__img {
      transform: scale(1.3);
      filter: grayscale(0); }
  .card__image {
    flex-basis: 40%;
    overflow: hidden;
    margin: auto 3rem; }
    @media only screen and (max-width: 75em) {
      .card__image {
        flex-basis: 30%; } }
    @media only screen and (max-width: 56.25em) {
      .card__image {
        flex-basis: 20%; } }
    @media only screen and (max-width: 37.5em) {
      .card__image {
        flex-basis: 0;
        margin: auto 0rem; } }
  .card__img {
    height: 15rem;
    width: 100%;
    transition: all 0.3s;
    filter: grayscale(1); }
  .card__content {
    flex-basis: 60%;
    overflow: hidden;
    margin: 2rem;
    text-overflow: ellipsis; }
    @media only screen and (max-width: 37.5em) {
      .card__content {
        flex-basis: 100%; } }

.contactMeWidget {
  position: relative;
  color: var(--color-secondary);
  padding: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .contactMeWidget__logo {
    position: relative;
    z-index: 1;
    opacity: 0.85; }

.posts {
  grid-row: 2/3;
  grid-column: 1/-1;
  background-color: var(--color-secondary);
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 60% 1fr;
  gap: 1.5rem;
  padding-top: 3rem; }
  @media only screen and (max-width: 75em) {
    .posts {
      grid-template-columns: 1fr 65% 1fr; } }
  @media only screen and (max-width: 56.25em) {
    .posts {
      grid-template-columns: 1fr 70% 1fr; } }
  @media only screen and (max-width: 37.5em) {
    .posts {
      grid-template-columns: 1fr 80% 1fr; } }
  .posts__cards-container {
    grid-column: 2/3;
    display: flex;
    flex-direction: column; }

.star-rating {
  min-width: fit-content; }
  .star-rating.primary {
    color: var(--color-secondary); }
  .star-rating.secondary {
    color: var(--color-primary); }

.star {
  margin: 0 0.2rem;
  transition: transform 0.3s; }
  .star.secondary {
    color: var(--color-secondary); }
    .star.secondary:hover {
      transform: translateY(-0.5rem);
      background-color: rgba(255, 255, 255, 0.15);
      box-shadow: 0.5rem 0.5rem 3rem var(--color-secondary); }
    .star.secondary.active {
      color: var(--color-secondary); }
  .star.primary {
    color: var(--color-primary); }
    .star.primary:hover {
      transform: translateY(-0.5rem);
      background-color: rgba(255, 255, 255, 0.15);
      box-shadow: 0.5rem 0.5rem 3rem var(--color-primary); }
    .star.primary.active {
      color: var(--color-primary); }

.rating__holder {
  display: flex;
  margin: 1.8rem 10rem 0 0;
  min-width: 25rem;
  justify-content: space-between; }
  .rating__holder-heading {
    text-align: start; }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  scrollbar-color: var(--color-secondary) var(--color-primary); }

a {
  text-decoration: none;
  color: inherit; }
  a:focus, a:focus-visible {
    outline-color: var(--color-secondary); }

ul {
  list-style-type: none;
  padding: 0; }

html {
  scroll-behavior: smooth;
  font-size: 62.5%; }
  @media only screen and (max-width: 75em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 50%; } }
  @media only screen and (max-width: 37.5em) {
    html {
      font-size: 43.75%; } }
  @media only screen and (min-width: 112.5em) {
    html {
      font-size: 75%; } }

body {
  box-sizing: border-box;
  font-family: Share Tech Mono, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

*::-webkit-scrollbar {
  width: 1rem; }

*::-webkit-scrollbar-track {
  transition: all 0.3s;
  background-image: radial-gradient(var(--color-secondary), var(--color-primary)); }

*::-webkit-scrollbar-thumb {
  background-image: radial-gradient(var(--color-primary) 0%, var(--color-secondary) 50%);
  border-radius: 1rem;
  box-shadow: 0.5rem 0.5rem 3rem var(--color-secondary); }
